/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: $TSFixMe; // ArgsOf<ContentEditor::Panels::StandardHeader> that does not have a backing file
}

const AiAgentProfilePreHandoverHeader = templateOnlyComponent<Signature>();

export default AiAgentProfilePreHandoverHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Custom::AiAgentProfile::PreHandover::Header': typeof AiAgentProfilePreHandoverHeader;
    'content-editor/panels/custom/ai-agent-profile/pre-handover/header': typeof AiAgentProfilePreHandoverHeader;
  }
}
