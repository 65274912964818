/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    config: $TSFixMe;
  };
}

const ResolutionBotBehaviorPreHandoverSummary = templateOnlyComponent<Signature>();

export default ResolutionBotBehaviorPreHandoverSummary;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Summaries::Custom::ResolutionBotBehavior::PreHandover::Summary': typeof ResolutionBotBehaviorPreHandoverSummary;
    'content-editor/summaries/custom/resolution-bot-behavior/pre-handover/summary': typeof ResolutionBotBehaviorPreHandoverSummary;
  }
}
