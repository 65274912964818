/* import __COLOCATED_TEMPLATE__ from './learn-content-suggestion.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/ai-content-library/constants';

export default class LearnContentSuggestion extends Component {
  @service declare appService: any;
  @service declare router: any;

  get finContentConfigRoute() {
    if (this.appService.app.canUseFinExperience) {
      return 'apps.app.fin-ai-agent.setup';
    }
    return 'apps.app.automation.resolution-bot.setup';
  }

  @action
  redirectToConfigureContentSuggestions() {
    this.router.transitionTo(this.finContentConfigRoute, {
      queryParams: { openSectionId: 'conversation-content' },
    });
  }

  @action
  showArticleContentSuggestions() {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.contentSuggestions);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::LearnDropdown::LearnContentSuggestion': typeof LearnContentSuggestion;
  }
}
