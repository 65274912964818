/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { hash } from 'ember-concurrency';
import {
  CHANNEL_METADATA_MAPPING,
  channelGroupMapping,
} from 'embercom/lib/operator/custom-bots/constants';

export default class ChannelTargeting extends Component {
  @service contentEditorService;
  @service store;
  @service appService;
  @service helpCenterService;

  constructor() {
    super(...arguments);
    this.loadChannelGroups.perform();
  }

  get hasMultipleHelpCenterSites() {
    return this.helpCenterService.sites?.length > 1;
  }

  get showMultiHcMessengerWarning() {
    return this.appService.app?.canUseMultipleHelpCenters && this.hasMultipleHelpCenterSites;
  }

  get emailSelected() {
    return this.contentEditorService.activeObject?.targetChannels?.includes('email');
  }

  targetChannelGroup(targetChannelGroup) {
    return channelGroupMapping(this.appService.app.enableTwitterIntegrationBots)
      [targetChannelGroup].filter((channel) => {
        return !this.args.config?.excludedChannels?.includes(channel);
      })
      .map((channel) => {
        return CHANNEL_METADATA_MAPPING[channel];
      });
  }

  @task({ drop: true })
  *loadChannelGroups() {
    this.channelGroup = yield hash({
      intercom: this.loadIntercomChannels,
      social: this.loadSocialChannels.perform(),
      other: this.loadOtherChannels.perform(),
    });
  }

  get loadIntercomChannels() {
    return this.targetChannelGroup('intercom').map((channel) => {
      let isSetup = true;
      return {
        ...channel,
        isSetup,
      };
    });
  }

  @task({ drop: true })
  *loadSocialChannels() {
    let apps = yield this.store.query('appstore/app-package', { installed: true });
    return this.targetChannelGroup('social').map((channel) => {
      let isInstalled = apps.mapBy('id').includes(channel.appId);
      let isSetup = isInstalled;
      return {
        ...channel,
        isInstalled,
        isSetup,
        isDisabled: !isInstalled || !isSetup,
      };
    });
  }

  @task({ drop: true })
  *loadOtherChannels() {
    let smsOutboundSettings = yield this.store.findAll('sms-outbound-settings');
    let smsSetup = smsOutboundSettings?.firstObject?.twoWaySmsEnabled;

    return this.targetChannelGroup('other').map((channel) => {
      let isSetup = channel.value === 'email' || smsSetup || channel.value === 'phone_call';
      return {
        ...channel,
        isDisabled: !isSetup,
        isSetup,
      };
    });
  }
}
