/* import __COLOCATED_TEMPLATE__ from './workflow-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE } from 'embercom/lib/operator/custom-bots/constants';
import {
  customBotObjectTypes,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  OUTBOUND_EDITOR_ROUTES,
  OUTBOUND_INDEX_ROUTES,
} from 'embercom/models/outbound/content-wrapper';
import PredicateGroup from 'predicates/models/predicate-group';
import { tracked } from '@glimmer/tracking';

export default class WorkflowHeader extends Component {
  @service contentEditorService;
  @service store;
  @service router;
  @service intl;
  @service intercomConfirmService;
  @service appService;
  @service guideLibraryService;

  @tracked hasSaved = false;
  @tracked hasDismissedOptInCopy = false;

  maxTitleLength = 255;

  constructor() {
    super(...arguments);
    if (this.triggerPredicates && this.customBot && !this.customBot.triggerPredicateGroup) {
      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            {
              type: this.triggerPredicates.type,
              predicates: [this.triggerPredicates.options.firstObject.predicate],
            },
          ]),
        },
      );
    }

    //initialize the editor configs for all rulesets
    this.contentEditorService.ruleset.rulesetLinks.forEach((rulesetLink) => {
      if (customBotObjectTypes.includes(rulesetLink.objectType)) {
        rulesetLink.object.visualBuilderObject.editorConfig =
          rulesetLink.object.config.editorConfig;
      }
    });
  }

  get triggerPredicates() {
    if (!this.customBot?.config) {
      return;
    }

    return this.customBot.config.triggerPredicates;
  }

  get customBot() {
    return this.contentEditorService.activeObject;
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get isStandaloneMode() {
    return this.appService.app.canUseStandalone;
  }

  get showMessengerBanner() {
    return (
      this.contentEditorService?.activeRulesetLink?.objectType === objectTypes.inboundCustomBot
    );
  }

  get targetChannelIsOnlyEmail() {
    if (this.customBot.targetChannels?.every((item) => item === 'email')) {
      return true;
    }
  }

  get isBannerRequired() {
    if (this.targetChannelIsOnlyEmail) {
      return false;
    }
    if (!this.isMessengerInstalled) {
      return true;
    }
  }

  get workflow() {
    let { editorConfig } = this.customBot.config;
    let workflow = this.customBot.visualBuilderObject;
    workflow.editorConfig = editorConfig;

    return workflow;
  }

  get editorConfiguration() {
    let { editorConfig } = this.customBot.config;
    if (!editorConfig) {
      throw new Error(
        `Cannot resolve path configuration class for custom bot type=${this.customBot.type}`,
      );
    }
    return editorConfig;
  }

  get isBackgroundWorkflow() {
    return this.contentEditorService.activeObject.visualBuilderObject?.isCustomerFacing === false;
  }

  get shouldShowOptInCopy() {
    return !this.hasDismissedOptInCopy && this.customBot.shouldShowOptInCopy;
  }

  @action
  async save() {
    if (
      this.args.config.preActivationCheck &&
      (this.contentEditorService.ruleset.isLive || this.contentEditorService.ruleset.isScheduled)
    ) {
      this.args.config.preActivationCheck(this.saveRuleset);
      return;
    }
    this.saveRuleset.perform();
    this.hasSaved = true;
  }

  @restartableTask
  *saveRuleset() {
    yield this.contentEditorService.saveRuleset.perform();
  }

  @action
  async close() {
    this.contentEditorService.uploadFileFinished();

    if (this.contentEditorService.ruleset.hasUnsavedChanges) {
      let confirmed = await this.confirmUnsavedChanges();
      if (!confirmed) {
        return;
      }

      this.contentEditorService.ruleset.rollbackAttributes();
    }

    if (this.isStandaloneMode) {
      this.router.transitionTo('apps.app.standalone.setup');
    } else if (this.contentEditorService.returnPath) {
      this.router.transitionTo(this.contentEditorService.returnPath);
    } else {
      this.contentEditorService.changeToViewMode();
      this.router.currentRouteName === OUTBOUND_EDITOR_ROUTES['custom_bot']
        ? this.router.transitionTo(OUTBOUND_INDEX_ROUTES['custom_bot'])
        : this.router.transitionTo(OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE);
    }
  }

  confirmUnsavedChanges() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('content-editor.headers.edit-mode.standard-header.unsaved-changes-title'),
      body: this.intl.t('content-editor.headers.edit-mode.standard-header.unsaved-changes-body'),
      confirmButtonText: this.intl.t(
        'content-editor.headers.edit-mode.standard-header.unsaved-changes-confirm',
      ),
      primaryButtonType: 'primary-destructive',
      cancelButtonText: this.intl.t(
        'content-editor.headers.edit-mode.standard-header.unsaved-changes-cancel',
      ),
    });
  }

  @action
  trimContentTitle() {
    this.contentEditorService.ruleset.clientData.title =
      this.contentEditorService.ruleset.clientData.title.trim();
  }
}
