/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';

interface Args {
  section?: $TSFixMe; // section from Common::AccordionComponent - not typed;
  index?: number;
  config?: $TSFixMe; // header config from app/objects/content-editor/panels/custom/resolution-bot-behavior/bot-mode/header-configuration.js
}

interface Signature {
  Args: Args;
}

export default class AiAgentProfileHeader extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare intl: IntlService;
  @service declare appService: any;

  get translationNamespace() {
    return this.appService.app.canUseFinExperience
      ? 'ai-agent.profiles.bot-mode-fin'
      : 'ai-agent.profiles.bot-mode';
  }

  get botMode(): string | undefined {
    if (this.contentEditorService.ruleset.isDeleted) {
      return undefined;
    }

    switch (this.contentEditorService.ruleset.rulesetLinks.firstObject.object.botMode) {
      case 'bot_only':
        return this.intl.t(`${this.translationNamespace}.looping-bot-only`);

      case 'one_time':
        return this.intl.t('ai-agent.profiles.bot-mode.one-time');

      case 'looping':
        return this.intl.t(`${this.translationNamespace}.looping`);

      case 'disabled':
        return this.intl.t('ai-agent.profiles.bot-mode.disabled');
      default:
        return undefined;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Custom::AiAgentProfile::BotMode::Header': typeof AiAgentProfileHeader;
    'content-editor/panels/custom/ai-agent-profile/bot-mode/header': typeof AiAgentProfileHeader;
  }
}
