/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
export default class GenericTriageHeader extends Component {
  @service contentEditorService;
  @service intl;

  get description() {
    if (!this.contentEditorService.activeObject.modeEligibleForGenericTriage) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    }

    let allLocalizations = this.contentEditorService.activeObject.genericTriageLocalizations;

    let enabledLocalizations = allLocalizations
      .filter((localization) => localization.enabled === true)
      .map((localization) =>
        capitalize(this.intl.t(`app.lib.languages.language-code.${localization.locale}`)),
      );

    if (allLocalizations.length === 1 && enabledLocalizations.length === 1) {
      return this.intl.t('ai-agent.profiles.handover.enabled');
    } else if (enabledLocalizations.length === 0) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    } else {
      return this.intl.t('ai-agent.profiles.bot-only.enabled-localizations', {
        list: this.intl.formatList(enabledLocalizations, { style: 'short', type: 'unit' }),
      });
    }
  }
}
