/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import { QUICK_REPLY_ID } from 'embercom/lib/operator/resolution-bot/constants';
import AiAgentProfileEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ai-agent-profile';

export default class GenericTriageSummary extends Component {
  @service contentEditorService;
  @service appService;
  @service('ai-agent-quick-replies-service') quickRepliesService;
  @service store;
  @service intl;

  get description() {
    if (!this.contentEditorService.activeObject.modeEligibleForGenericTriage) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    }

    let allLocalizations = this.contentEditorService.activeObject.genericTriageLocalizations;

    let enabledLocalizations = allLocalizations
      .filter((localization) => localization.enabled === true)
      .map((localization) =>
        capitalize(this.intl.t(`app.lib.languages.language-code.${localization.locale}`)),
      );

    if (allLocalizations.length === 1 && enabledLocalizations.length === 1) {
      return this.intl.t('ai-agent.profiles.handover.enabled');
    } else if (enabledLocalizations.length === 0) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    } else {
      return this.intl.t('ai-agent.profiles.handover.enabled-localizations', {
        list: this.intl.formatList(enabledLocalizations, { style: 'short', type: 'unit' }),
      });
    }
  }

  get defaultGenericTriage() {
    return this.contentEditorService.activeObject.genericTriageLocalizations.find(
      (localization) => localization.default === true,
    );
  }

  get firstSteps() {
    return this.defaultGenericTriage.paths.firstObject.steps;
  }

  get firstPathEndsWithReplyButtons() {
    return this.defaultGenericTriage.paths.firstObject.endStateStep.isReplyButtons;
  }

  get waitForTeamQuickReply() {
    return this.quickRepliesService.getLabel(
      QUICK_REPLY_ID.WAIT_FOR_THE_TEAM,
      this.customQuickReplies.waitForTheTeamLabel,
      this.defaultGenericTriage.locale,
    );
  }

  get customQuickReplies() {
    return this.store.peekRecord(
      'operator-settings/ai-agent-quick-replies',
      this.appService.app.id,
    );
  }

  get conversationSlas() {
    return this.store.peekAll('inbox/conversation-sla');
  }

  get officeHoursSchedules() {
    return this.store.peekAll('office-hours-schedule');
  }

  get editorConfiguration() {
    return new AiAgentProfileEditorConfig({
      targetChannels: this.contentEditorService.activeObject.targetChannels,
    });
  }
}
