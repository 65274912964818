/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BotIntroSummary extends Component {
  @service contentEditorService;
  @service store;
  @service appService;
  @service intl;
  @tracked previewConfiguration;

  get behavior() {
    return this.contentEditorService.activeObject;
  }
  get description() {
    if (this.behavior.botIntroEligibleAndEnabled) {
      return this.botIntroHeaderForEnabled;
    } else {
      if (this.behavior.botMode === 'one_time') {
        return this.intl.t('ai-agent.profiles.bot-intro.disabled-in-one-time');
      } else if (!this.behavior.isTargetingIntercomChannels) {
        return this.intl.t('ai-agent.profiles.bot-intro.not-applicable');
      }
      return this.intl.t('ai-agent.profiles.bot-intro.disabled');
    }
  }

  get botIntroHeaderForEnabled() {
    let availableLocalizations = this.behavior.botIntroLocalizations;
    let enabledLocalizations = this.behavior.enabledLocalizationsList.map((localization) =>
      capitalize(this.intl.t(`app.lib.languages.language-code.${localization}`)),
    );

    if (availableLocalizations.length === 1 && enabledLocalizations.length === 1) {
      return this.intl.t('ai-agent.profiles.bot-intro.enabled');
    } else {
      return this.intl.t('ai-agent.profiles.bot-intro.enabled-localizations', {
        list: this.intl.formatList(enabledLocalizations, { style: 'short', type: 'unit' }),
      });
    }
  }

  get defaultBotIntro() {
    return this.behavior.botIntroLocalizations.find(
      (localization) => localization.default === true,
    );
  }
}
