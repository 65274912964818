/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type { AiContentLibrarySummary } from 'embercom/services/content-import-service';
import type Profile from 'embercom/models/ai-agent/profile';
import { taskFor } from 'ember-concurrency-ts';

export default class AiAnswerBody extends Component {
  @service declare contentEditorService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked useCustomAnswers = this.behavior.useCustomAnswers;
  @tracked answerCount: number | null = null;
  @tracked aiContentReadyForFin = true;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.loadAiContentReadyForFin).perform();
    taskFor(this.loadAnswerCount).perform();
    this.maybeDisableAiAnswers();
    this.maybeDisableCustomAnswers();
  }

  get app() {
    return this.appService.app;
  }

  get behavior(): Profile {
    return this.contentEditorService.activeObject;
  }

  get hasExpiredTrial() {
    return this.app.aiAnswersState === 'trial_expired';
  }

  get shouldShowOptInCopy() {
    return (
      (this.app.wasCreatedAfterFinGeneralRelease ||
        this.app.wasCreatedAfterCardlessFinTrialRelease) &&
      !this.app.hasOptedInToFin
    );
  }

  get isTestApp() {
    return this.app.isTestApp;
  }

  get finIsInactive() {
    return (
      this.app.canUseFeature('ai_answers_billing_feature') && this.app.aiAnswersState === 'inactive'
    );
  }

  get shouldDisableAiAnswersWithoutPaywall() {
    return this.hasExpiredTrial || this.isTestApp || this.finIsInactive;
  }

  get showNoAiContentBanner() {
    return this.behavior.useAiAnswers && !this.aiContentReadyForFin;
  }

  get showNoLiveCustomAnswersBanner() {
    return this.useCustomAnswers && this.answerCount === 0;
  }

  maybeDisableCustomAnswers() {
    if (!this.appService?.app?.hasAnswerBot) {
      this.behavior.useCustomAnswers = false;
      this.useCustomAnswers = false;
    }
  }

  maybeDisableAiAnswers() {
    if (this.finIsInactive && this.behavior.useAiAnswers) {
      this.behavior.useAiAnswers = false;
    }
  }

  @action
  toggleAiAnswer() {
    this.behavior.toggleProperty('useAiAnswers');
    if (this.behavior.useAiAnswers) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'enabled_ai_answers',
        object: 'ai_answers_toggle',
        context: 'answers',
        place: 'fin_setup',
      });
    } else {
      this.behavior.preHandoverAnswerEnabled = false;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'disabled_ai_answers',
        object: 'ai_answers_toggle',
        context: 'answers',
        place: 'fin_setup',
      });
    }
    // onetime not allowed with AI answers
    if (
      this.behavior.useAiAnswers &&
      (this.behavior.botMode === 'one_time' || this.behavior.botMode === 'disabled')
    ) {
      this.behavior.botMode = 'looping';
      this.behavior.maybeResetDefaultInactivityValues();
    }
  }

  @action
  toggleCustomAnswer() {
    this.behavior.toggleProperty('useCustomAnswers');
    this.useCustomAnswers = this.behavior.useCustomAnswers;

    if (this.behavior.useCustomAnswers && this.behavior.botMode === 'disabled') {
      this.behavior.botMode = 'looping';
    }
  }

  @task({ restartable: true })
  *loadAnswerCount() {
    let answers: $TSFixMe[] = yield this.store.findAll('custom-answers/custom-answer', {
      reload: true,
    });
    this.answerCount = answers.filter((answer: $TSFixMe) => answer.status === 'live').length;
  }

  @task({ restartable: true })
  *loadAiContentReadyForFin() {
    let librarySummary: AiContentLibrarySummary = yield get(
      '/ember/content_service/contents/ai_content_library_summary',
      {
        app_id: this.appService.app.id,
      },
    );
    this.aiContentReadyForFin = Object.values(librarySummary).some(
      (contentSummary) => contentSummary.used_by_fin_count > 0,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Custom::AiAgentProfile::Answers::Body': typeof AiAnswerBody;
    'content-editor/panels/custom/ai-agent-profile/answers/body': typeof AiAnswerBody;
  }
}
