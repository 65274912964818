/* import __COLOCATED_TEMPLATE__ from './add-source-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ContentImportService from 'embercom/services/content-import-service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import isValidUrl, { urlDomainsMatch } from 'embercom/lib/url-validator';
import { EntityType } from 'embercom/models/data/entity-types';
import { TriggerSources } from 'embercom/objects/content-service/content-imports/constants/trigger-sources';
import { action } from '@ember/object';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  modal: any;
  onModalClose: () => void;
  onNavigationBack?: () => void;
  showBackButton?: boolean;
}

export default class AddSourceModal extends Component<Args> {
  @service declare contentImportService: ContentImportService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare intl: IntlService;

  @tracked sourceUrl = '';
  @tracked additionalUrls = '';
  @tracked additionalUrlsArray: string[] = [];
  @tracked submissionErrorTranslationKey: string | undefined = undefined;
  @tracked openSectionId = '';

  get isUrlInvalid(): boolean {
    return !isValidUrl(this.sourceUrl);
  }

  get isAddingNewSource(): boolean {
    return taskFor(this.addNewSource).isRunning;
  }

  get isAdditionalUrlsInvalid(): boolean {
    if (this.additionalUrls === '') {
      return false;
    }
    if (this.sourceUrl === '') {
      return true;
    }
    for (let additionalUrl of this.additionalUrls.split(',')) {
      additionalUrl = additionalUrl.trim();
      if (additionalUrl !== '') {
        // Ignore empty strings caused by trailing or stray commas

        if (!isValidUrl(additionalUrl) || !urlDomainsMatch(this.sourceUrl, additionalUrl)) {
          return true;
        }
        this.additionalUrlsArray.push(additionalUrl);
      }
    }

    return false;
  }

  get formIsInvalid(): boolean {
    return this.isUrlInvalid || this.isAdditionalUrlsInvalid;
  }

  get learnMoreUrl() {
    return this.appService.app.canUseKnowledgeHub
      ? 'https://www.intercom.com/help/en/articles/9357945-syncing-and-managing-public-urls'
      : 'https://www.intercom.com/help/en/articles/7837514-setup-and-preview-fin-with-your-support-content#h_e40b096b38';
  }

  @task
  *addNewSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'import_source_button');

    if (!this.isUrlInvalid && !this.isAdditionalUrlsInvalid) {
      let params = {
        app_id: this.appService.app.id,
        url: this.sourceUrl,
        additional_urls: this.additionalUrlsArray,
        trigger_source: TriggerSources.AdminInitiated,
        content_type: EntityType.ExternalContent,
        admin_id: this.appService.app.currentAdmin.id,
      };

      try {
        this.submissionErrorTranslationKey = undefined;
        yield post(`/ember/content_service/content_import_runs`, params);
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.sync-modal.source.sync-started'),
        );
        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.sourceUrl = '';
        this.additionalUrls = '';
        this.additionalUrlsArray.clear();
        this.args.onModalClose();
        if (this.guideLibraryService.canUseGuideLibraryService) {
          this.guideLibraryService.subscribeToContentImportUpdates();
        }
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_source_input');
  }

  @action trackAdditionalUrlsAnalytics() {
    this.trackAnalyticsEvent('inputed', 'additional_urls_input');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
      section: 'operator',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::AddSourceModal': typeof AddSourceModal;
  }
}
