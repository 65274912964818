/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  section?: $TSFixMe; // section from Common::AccordionComponent - not typed
  index?: number;
  config?: $TSFixMe; // config object from embercom/objects/content-editor/component-configuration - not typed
}

interface Signature {
  Args: Args;
}

const AiAgentProfilePreHandoverBody = templateOnlyComponent<Signature>();

export default AiAgentProfilePreHandoverBody;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Custom::AiAgentProfile::PreHandover::Body': typeof AiAgentProfilePreHandoverBody;
    'content-editor/panels/custom/ai-agent-profile/pre-handover/body': typeof AiAgentProfilePreHandoverBody;
  }
}
