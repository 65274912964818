/* import __COLOCATED_TEMPLATE__ from './save-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  type Scope,
  PERMISSIONS,
} from 'embercom/objects/content-service/saved-views/constants/scopes';
import type SavedView from 'embercom/objects/content-service/saved-views/saved-view';
import type SavedViewData from 'embercom/objects/content-service/saved-views/saved-view-data';
import { inject as service } from '@ember/service';

interface Args {
  scope: Scope;
  savedViewData: SavedViewData;
  onSave: Function;
  position: string;
}

interface CloseModalArgs {
  success: boolean;
  savedView: SavedView;
}

export default class SaveButton extends Component<Args> {
  @service permissionsService: any;
  @service intercomEventService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get hasPermission() {
    return this.permissionsService.currentAdminCan(PERMISSIONS[this.args.scope].edit);
  }

  @tracked modalIsOpen = false;

  @action openModal() {
    this.modalIsOpen = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: this.args.position ? `saved_view_button_${this.args.position}` : 'saved_view_button',
    });
  }

  @action closeModal(args: CloseModalArgs) {
    this.modalIsOpen = false;
    if (args.success) {
      this.args.onSave({ savedView: args.savedView });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::SavedViews::SaveButton': typeof SaveButton;
    'content-service/saved-views/save-button': typeof SaveButton;
  }
}
